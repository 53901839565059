<template>
  <div>
    <div class="row mb-5">
      <span class="display-4 col-12">
        {{ `${establishment.company_name}` }} - {{ $t("COMMON.CONTACTS") }}
      </span>

      <div class="col-12 mt-4">
        <base-button
          type="primary"
          icon
          size="sm"
          @click="addEstablishmentContact()"
          v-if="$currentUserCan($permissions.PERM_CREATE_CONTACTS)"
        >
          <span class="btn-inner--icon">
            <i class="fas fa-plus"></i>
          </span>
          <span class="btn-inner--text">{{ $t("COMMON.ADD_CONTACT") }}</span>
        </base-button>
      </div>
    </div>

    <establishment-view-contacts-add-contact-modal
      :establishment="establishment"
      :showModal="showContactAddModal"
      @onCloseModal="onCloseModal"
    />

    <contact-list-table
      :filterOrganization="establishment.organization.id"
      :filterContactableType="`App\\Models\\Establishment`"
      :filterContactableId="establishment.id"
      :key="rerenderKey"
    />
  </div>
</template>

<script>
import ContactListTable from "../../../CrmModule/ContactManagement/partials/ContactListTable.vue";
import EstablishmentViewContactsAddContactModal from "./EstablishmentViewContactsAddContactModal.vue";

export default {
  name: "establishment-view-contacts",

  components: { EstablishmentViewContactsAddContactModal, ContactListTable },

  props: ["establishment"],

  data() {
    return {
      showContactAddModal: false,
      rerenderKey: 0,
    };
  },

  computed: {},

  created() {},

  methods: {
    addEstablishmentContact() {
      this.showContactAddModal = true;
    },

    onCloseModal() {
      this.showContactAddModal = false;
      this.$emit("establishmentUpdated", true);
      this.rerenderKey++;
    },
  },

  mounted() {},

  watch: {
    establishment(establishment) {},
  },
};
</script>
