<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <h1>{{ `${establishment.company_name}` }}</h1>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("USERS.ACTIVE") }}</dt>
          <dd class="col-sm-8">
            <icon-check :checked="!!establishment.active" />
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("COMMON.TAGS") }}
          </dt>
          <dd class="col-sm-8">
            <tags
              :tags="establishment.tags"
              @tagsUpdated="establishmentUpdated"
            />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.TYPE") }}</dt>
          <dd class="col-sm-8" v-if="establishment.establishment_type">
            {{
              $t(
                `ESTABLISHMENTS.ESTABLISHMENT_TYPE_${establishment.establishment_type}`
              )
            }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.FISCAL_NUMBER") }}</dt>
          <dd class="col-sm-8">
            {{ establishment.fiscal_number }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CUSTOMER_NUMBER") }}</dt>
          <dd class="col-sm-8">
            {{ establishment.customer_number }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
          <dd class="col-sm-8">
            {{ establishment.address }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EMAIL") }}</dt>
          <dd class="col-sm-8">
            {{ establishment.email }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.PHONE") }}</dt>
          <dd class="col-sm-8">
            {{ establishment.phone }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.COUNTRY") }}</dt>
          <dd class="col-sm-8">
            {{ establishment.country }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATE") }}</dt>
          <dd class="col-sm-8">
            {{ establishment.state }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CITY") }}</dt>
          <dd class="col-sm-8">
            {{ establishment.city }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ZIPCODE") }}</dt>
          <dd class="col-sm-8">
            {{ establishment.zipcode }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
          <dd class="col-sm-8">
            {{ establishment.address }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd class="col-sm-8">
            <locations :locations="establishment.allowedLocations" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CUSTOMERS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.CUSTOMER") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="establishment.customer" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SERVICECENTERS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.SERVICECENTER") }}</dt>
          <dd class="col-sm-8">
            <service-center :serviceCenter="establishment.serviceCenter" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="establishment.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ establishment.created_at | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ establishment.updated_at | moment("LLLL") }}
          </dd>
        </dl>

        <div class="col-12 text-center mb-2 mt-5">
          <h2>{{ $t("INVOICES.BILLING_ADDRESS") }}</h2>
        </div>

        <billing-informations-view
          :billingInformations="establishment"
          :type="establishment.billing_entity_type"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Tags from "@/components/Tags.vue";
import BillingInformationsView from "@/components/BillingInformationsView.vue";
import IconCheck from "@/components/IconCheck.vue";
import ServiceCenter from "@/components/ServiceCenter.vue";
import ObjectLink from "@/components/ObjectLink.vue";
import {
  ESTABLISHMENT_TYPE_ELEMENTARY,
  ESTABLISHMENT_TYPE_SECONDARY,
  ESTABLISHMENT_TYPE_COLLEGE,
  ESTABLISHMENT_TYPE_UNIVERSITY,
} from "@/constants/establishments";

export default {
  name: "establishment-view-global",

  components: {
    IconCheck,
    Tags,
    BillingInformationsView,
    ServiceCenter,
    ObjectLink,
  },

  props: ["establishment"],

  data() {
    return {
      ESTABLISHMENT_TYPE_ELEMENTARY: ESTABLISHMENT_TYPE_ELEMENTARY,
      ESTABLISHMENT_TYPE_SECONDARY: ESTABLISHMENT_TYPE_SECONDARY,
      ESTABLISHMENT_TYPE_COLLEGE: ESTABLISHMENT_TYPE_COLLEGE,
      ESTABLISHMENT_TYPE_UNIVERSITY: ESTABLISHMENT_TYPE_UNIVERSITY,
    };
  },

  computed: {},

  methods: {
    establishmentUpdated() {
      this.$emit("establishmentUpdated", true);
    },
  },

  watch: {
    establishment(establishment) {},
  },
};
</script>
