<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-10">
              <h3 class="mb-0">
                {{ establishment.company_name }}
                <base-button
                  v-if="$currentUserCan($permissions.PERM_EDIT_ESTABLISHMENTS)"
                  type="primary"
                  icon
                  size="sm"
                  @click="editEstablishment()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-edit"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="
                    $currentUserCan($permissions.PERM_EDIT_ESTABLISHMENTS) &&
                    !establishment.active
                  "
                  type="info"
                  icon
                  size="sm"
                  :disabled="loading"
                  @click="activateEstablishment()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-power-off"></i>
                    {{ $t("ESTABLISHMENTS.ACTIVATE") }}
                  </span>
                </base-button>

                <base-button
                  v-if="
                    $currentUserCan($permissions.PERM_EDIT_ESTABLISHMENTS) &&
                    establishment.active
                  "
                  type="warning"
                  icon
                  size="sm"
                  :disabled="loading"
                  @click="deactivateEstablishment()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-power-off"></i>
                    {{ $t("ESTABLISHMENTS.DESACTIVATE") }}
                  </span>
                </base-button>

                <base-button
                  v-if="
                    $currentUserCan($permissions.PERM_DELETE_ESTABLISHMENTS)
                  "
                  type="danger"
                  icon
                  size="sm"
                  @click="deleteEstablishment()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-trash"></i>
                  </span>
                </base-button>
              </h3>
            </div>
            <div class="col-2 text-right">
              <base-button
                @click="goBack()"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
        </template>
        <div>
          <div
            class="col-12 justify-content-center justify-content-sm-between flex-wrap"
          >
            <tabs
              fill
              class="flex-column flex-md-row"
              tabNavWrapperClasses="nav-wrapper"
              tabNavClasses="nav nav-pills nav-fill"
              value="global"
            >
              <card shadow>
                <tab-pane title="global" id="1" :active="true">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.GLOBAL") }}
                  </span>
                  <establishment-view-global :establishment="establishment" />
                </tab-pane>

                <tab-pane
                  title="cafeterias"
                  id="2"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CAFETERIAS)"
                >
                  <span slot="title">
                    <i class="ni ni-folder-17" />
                    {{ $t("COMMON.CAFETERIAS") }}
                  </span>
                  <establishment-view-cafeterias
                    :establishment="establishment"
                  />
                </tab-pane>

                <tab-pane
                  title="contacts"
                  id="3"
                  :active="true"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CONTACTS)"
                >
                  <span slot="title">
                    <i class="ni ni-circle-08" />
                    {{ $t("COMMON.CONTACTS") }}
                  </span>
                  <establishment-view-contacts :establishment="establishment" />
                </tab-pane>

                <tab-pane
                  title="orders"
                  id="4"
                  :active="true"
                  v-if="
                    $currentUserCan($permissions.PERM_VIEW_ANY_SALES_ORDERS)
                  "
                >
                  <span slot="title">
                    <i class="ni ni-bag-17" />
                    {{ $t("COMMON.SALES_ORDERS") }}
                  </span>
                  <establishment-view-orders :establishment="establishment" />
                </tab-pane>

                <tab-pane
                  title="files"
                  id="998"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_FILES)"
                >
                  <span slot="title">
                    <i class="ni ni-folder-17" />
                    {{ $t("COMMON.FILES") }}
                  </span>
                  <list-file-component :object="establishment" />
                </tab-pane>

                <tab-pane
                  title="logs"
                  id="999"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
                >
                  <span slot="title">
                    <i class="fa fa-file" />
                    {{ $t("COMMON.LOGS") }}
                  </span>
                  <establishment-view-logs :establishment="establishment" />
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import EstablishmentViewGlobal from "./partials/EstablishmentViewGlobal.vue";
import EstablishmentViewOrders from "./partials/EstablishmentViewOrders.vue";
import EstablishmentViewLogs from "./partials/EstablishmentViewLogs.vue";
import EstablishmentViewContacts from "./partials/EstablishmentViewContacts.vue";
import EstablishmentViewCafeterias from "./partials/EstablishmentViewCafeterias.vue";
import defaultEstablishment from "./defaultEstablishment";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    EstablishmentViewGlobal,
    EstablishmentViewOrders,
    EstablishmentViewLogs,
    EstablishmentViewContacts,
    EstablishmentViewCafeterias,
    ListFileComponent,
  },

  mixins: [requestErrorMixin],

  data() {
    return {
      establishment: cloneDeep(defaultEstablishment),
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("establishments/get", id);
        this.establishment =
          this.$store.getters["establishments/establishment"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async editEstablishment(row) {
      this.$router.push({
        name: "Edit Establishment",
        params: { id: this.establishment.id },
      });
    },

    async deleteEstablishment() {
      const confirmation = await swal.fire({
        title: this.$t("ESTABLISHMENTS.DELETE_THIS_ESTABLISHMENT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "establishments/destroy",
            this.establishment.id
          );
          await this.goBack();
          this.$notify({
            type: "success",
            message: this.$t("ESTABLISHMENTS.ESTABLISHMENT_DELETED"),
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    goBack() {
      this.$router.push({ name: "List Establishments" });
    },

    async activateEstablishment() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "establishments/activate",
          this.establishment.id
        );
        this.establishment =
          this.$store.getters["establishments/establishment"];
        this.$notify({
          type: "success",
          message: this.$t("USERS.USER_ACTIVATED"),
        });
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async deactivateEstablishment() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "establishments/deactivate",
          this.establishment.id
        );
        this.establishment =
          this.$store.getters["establishments/establishment"];
        this.$notify({
          type: "success",
          message: this.$t("USERS.USER_DEACTIVATED"),
        });
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },
  },
};
</script>
