<template>
  <div>
    <div class="row mb-5">
      <span class="display-4 col-12">
        {{ `${establishment.company_name}` }} -
        {{ $t("COMMON.CAFETERIAS") }}
      </span>
    </div>

    <cafeteria-list-table :filterSubject="establishment" />
  </div>
</template>
<script>
import CafeteriaListTable from '../../CafeteriaManagement/partials/CafeteriaListTable.vue';

export default {
  name: "establishment-view-cafeterias",

  components: { CafeteriaListTable },

  props: ["establishment"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {
    addEstablishmentCafeteras() {
      this.showCafeteriaAddModal = true;
    },

    onCloseModal() {
      this.showCafeteriaAddModal = false;
      this.$emit("establishmentUpdated", true);
      this.rerenderKey++;
    },
  },

  mounted() {},

  watch: {
    establishment(establishment) {},
  },
};
</script>
