<template>
  <div>
    <div class="row mb-5">
      <div class="col-12" style="display: flex; align-items: center">
        <span class="display-4">
          {{ `${establishment.company_name}` }} -
          {{ $t("COMMON.SALES_ORDERS") }}
        </span>
        <base-button
          type="primary"
          icon
          size="sm"
          @click="addSalesOrder()"
          :disabled="loading"
        >
          <span class="btn-inner--icon">
            <i class="ni ni-single-copy-04"></i>
            {{ $t("ESTABLISHMENTS.ADD_SALES_ORDER") }}
          </span>
        </base-button>
      </div>
    </div>

    <sales-order-list-table
      :filterCustomer="establishment.id"
      :filterRecipient="{ type: 'customers', id: establishment.customer.id }"
    />
  </div>
</template>

<script>
import moment from "moment";
import { cloneDeep } from "lodash";
import defaultSalesOrder from "../../../SalesModule/SalesOrderManagement/defaultSalesOrder";
import SalesOrderListTable from "../../../SalesModule/SalesOrderManagement/partials/SalesOrderListTable.vue";

export default {
  name: "establishment-view-orders",

  components: {
    SalesOrderListTable,
  },

  props: ["establishment"],

  data() {
    return {
      loading: false,
    };
  },

  computed: {},

  created() {},

  methods: {
    async addSalesOrder() {
      this.loading = true;
      const salesOrderData = cloneDeep(defaultSalesOrder);
      delete salesOrderData.salesInvoice;
      salesOrderData.expiration_time = moment(
        salesOrderData.expiration_time
      ).toISOString();

      salesOrderData.recipient = this.establishment.customer;
      salesOrderData.organization = this.establishment.organization;
      salesOrderData.allowedLocations = this.establishment.allowedLocations;
      salesOrderData.billing_entity_type =
        this.establishment.billing_entity_type;
      salesOrderData.billing_company_name =
        this.establishment.billing_company_name;
      salesOrderData.billing_firstname = this.establishment.billing_firstname;
      salesOrderData.billing_lastname = this.establishment.billing_lastname;
      salesOrderData.billing_country = this.establishment.billing_country;
      salesOrderData.billing_state = this.establishment.billing_state;
      salesOrderData.billing_city = this.establishment.billing_city;
      salesOrderData.billing_zipcode = this.establishment.billing_zipcode;
      salesOrderData.billing_address = this.establishment.billing_address;
      salesOrderData.billing_email = this.establishment.billing_email;

      try {
        await this.$store.dispatch("salesOrders/add", salesOrderData);
        this.$notify({
          type: "success",
          message: this.$t("SALES_ORDERS.SALES_ORDER_ADDED"),
        });
        const salesOrder = await this.$store.getters["salesOrders/salesOrder"];
        this.$router.push({
          name: "View SalesOrder",
          params: { id: salesOrder.id },
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },

  mounted() {},

  watch: {
    establishment(establishment) {},
  },
};
</script>
